import { forEach, map } from "lodash";
import { LANGUAGES } from "../constant/app";

export enum PaymentMethodType {
    DEFAULT = "DEFAULT",
    PAYMENT_2C2P = "PAYMENT_2C2P",
    BANK_TRANSFER = "BANK_TRANSFER",
    CREDIT_CARD = "CREDIT_CARD",
    CASH = "CASH",
    THAI_QR_PAYMENT = "THAI_QR_PAYMENT",
    CREDIT_CARD_POS = "CREDIT_CARD_POS",
    PAID_BY_CHECK = "PAID_BY_CHECK",
    EMPLOYEE_SALARY_DEDUCTION = "EMPLOYEE_SALARY_DEDUCTION",
}

export enum PaymentConfigurationStatus {
    ENABLE = "ENABLE",
    DISABLE = "DISABLE",
}

export interface IPaymentMethodTranslation {
    language: string;
    title: string;
    description: string;
}

export interface IBankTranslations {
    language: string;
    accountName: string;
    bankName: string;
}

export interface IPaymentMethod {
    id: string;
    name?: string;
    title?: any;
    paymentConfigurationType?: PaymentMethodType;
    status?: PaymentConfigurationStatus;
    priority?: any;
    maximumOrderAmount?: any;
    minimumOrderAmount?: any;
    description?: string;
    translations?: IPaymentMethodTranslation;
    configuration2C2P?: {
        mode?: any;
        merchantId?: string;
        secretKey?: string;
    };
    updatedAt?: Date;
    createdAt?: Date;
    [key: string]: any;
}

export interface I2C2PMethod {
    id: string;
    title?: any;
    status?: PaymentConfigurationStatus;
    option?: any;
    priority?: any;
    maximumOrderAmount?: any;
    minimumOrderAmount?: any;
    description?: string;
    translations?: IPaymentMethodTranslation[];
    updatedAt?: Date;
    createdAt?: Date;
    [key: string]: any;
}

export interface IBankAccount {
    id: string;
    title?: any;
    status?: PaymentConfigurationStatus;
    bankType: PaymentMethodType;
    bankName: string;
    bankLogo: string;
    accountName: string;
    accountNumber: string;
    qrPayment: string;
    translations?: IBankTranslations[];
    description: string;
    branch: any;
    updatedAt?: Date;
    createdAt?: Date;
    [key: string]: any;
}

export const mapPaymentMethodFromServer = (data: any): IPaymentMethod => {
    const { translations } = data ?? {};
    const dataTranslator: any = {};
    forEach(translations, (translate) => {
        dataTranslator[translate?.language] = {
            title: translate?.title,
            description: translate?.description,
        };
    });

    return {
        ...data,
        ...dataTranslator,
    };
};

export const mapPaymentMethodGeneralInfoToServer = (input: IPaymentMethod) => {
    const { status } = input;

    const translations = map(LANGUAGES, (item) => ({
        language: item.id,
        title: input[item?.id as keyof IPaymentMethod]?.title,
        description: input?.[item?.id as keyof IPaymentMethod]?.description,
    }));
    return {
        status,
        translations,
    };
};

export const map2C2PMethodFromServer = (data: any): I2C2PMethod => {
    const { translations } = data ?? {};
    const dataTranslator: any = {};
    forEach(translations, (translate) => {
        dataTranslator[translate?.language] = {
            title: translate?.title,
            description: translate?.description,
        };
    });

    return {
        ...data,
        ...dataTranslator,
    };
};

export const map2C2PMethodToServer = (input: I2C2PMethod) => {
    const { status, priority } = input;

    const translations = map(LANGUAGES, (item) => ({
        language: item.id,
        title: input[item?.id as keyof IPaymentMethod]?.title,
        description: input?.[item?.id as keyof IPaymentMethod]?.description,
    }));
    return {
        status,
        translations,
        priority: priority ? parseFloat(priority) : null,
    };
};

export const mapBankAccountFromServer = (data: any): IBankAccount => {
    const { translations } = data ?? {};
    const dataTranslator: any = {};
    forEach(translations, (translate) => {
        dataTranslator[translate?.language] = {
            bankName: translate?.bankName,
            accountName: translate?.accountName,
        };
    });

    return {
        ...data,
        ...dataTranslator,
    };
};

export const mapBankAccountToServer = (input: IBankAccount) => {
    const { status, priority, bankLogo, accountNumber, branch } = input;
    const translations = map(LANGUAGES, (item) => ({
        language: item.id,
        bankName: input[item?.id as keyof IBankAccount]?.bankName,
        accountName: input?.[item?.id as keyof IBankAccount]?.accountName,
    }));
    return {
        status,
        translations,
        priority: priority ? parseFloat(priority) : null,
        bankLogo,
        accountNumber,
        branch: branch?.id ?? null,
    };
};
