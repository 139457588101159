import { useContext } from "react";
import { CustomerCreateContext } from "../../../context/customer";
import Messages from "../../../languages/Messages";
import AgencySelect from "../../agency/share/AgencySelect";
import UserSelect from "../../user/share/UserSelect";

const CustomerCreateReferrer = () => {
    const { customerForm } = useContext(CustomerCreateContext);
    const { values, setFieldValue, errors } = customerForm || {};

    return (
        <div className="card-container p-3">
            <div className="p-3">
                <UserSelect
                    label={Messages.referrerSalePerson}
                    value={values?.salePerson}
                    error={errors?.salePerson}
                    onChange={(v) => {
                        setFieldValue("salePerson", v);
                    }}
                />
                <AgencySelect
                    className="mt-4"
                    label={Messages.refererAgency}
                    value={values?.agency}
                    onChange={(v) => {
                        setFieldValue("agency", v);
                    }}
                />
            </div>
        </div>
    );
};

export default CustomerCreateReferrer;
