import { Notifications } from "d-react-components";
import { isEmpty, uniq } from "lodash";
import { useCheckDiscountOrderForAdminMutation } from "../api/hooks";
import { getOrderPayload } from "../screen/order/crud/OrderCreate";

export const useCheckCartValue = ({
    setCartValue,
    billing,
    productList,
    productCustomList,
    manualDiscount,
    cartValue,
    orderMetrics,
}: any) => {
    const [checkDiscountOrder] = useCheckDiscountOrderForAdminMutation();

    const checkCartValue = (
        values: any,
        newVoucherList?: string[],
        applyToCart = true,
        newCredits?: string[]
    ) => {
        const orderPayload: any = getOrderPayload({
            values,
            billing,
            productList,
            productCustomList,
            manualDiscount,
            orderMetrics,
        });

        return checkDiscountOrder({
            variables: {
                payload: {
                    ...(orderPayload || {}),
                    voucherCode: uniq(
                        newVoucherList ||
                            cartValue?.voucher?.map(
                                (v: any) => v?.voucher?.code
                            )
                    ),
                    credits: uniq(
                        newCredits ||
                            cartValue?.credits?.map((v: any) => v?.credit?.id)
                    ),
                },
            },
            fetchPolicy: "no-cache",
        })
            .then((resp) => {
                const cart = resp?.data?.data?.data;
                const voucherList = cart?.voucher ?? [];
                const credits = cart?.credits ?? [];
                const newCart = {
                    ...cart,
                    voucher: voucherList,
                    credits,
                };
                if (!isEmpty(newCart) && applyToCart) {
                    setCartValue && setCartValue(newCart as any);
                }
                return newCart;
            })
            .catch((err) => {
                Notifications.showError(err);
            });
    };

    return { checkCartValue };
};
