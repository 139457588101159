import * as Yup from "yup";
import { ICountry } from "../interfaces/address";
import Messages from "../languages/Messages";

export const ProDistrictSchema = Yup.object().shape({
    id: Yup.string().required("Required field!"),
});

export const AddressSchema = Yup.object().shape({
    firstName: Yup.string().required("Required field!"),
    lastName: Yup.string().required("Required field!"),
    phone: Yup.string()
        .min(6, "Phone number is too short!")
        .max(10, "Phone number is too long!")
        .required("Required field!"),

    // country: Yup.string().required("Required field!"),
    province: ProDistrictSchema,
    district: ProDistrictSchema,
    subDistrict: ProDistrictSchema,
    postcode: Yup.string().required("Required field!"),
    address: Yup.string().required("Required field!"),
});

export const CustomerAddressSchema = Yup.lazy((value) => {
    return AddressSchema.concat(
        Yup.object().shape({
            typeOfPlace: Yup.string().required("Required field!"),
        }) as any
    );
});

export const BillingSchema = Yup.lazy((value: any) => {
    const schema = AddressSchema.concat(
        Yup.object().shape({
            typeOfCustomer: Yup.string().required("Required field!"),
        }) as any
    );
    if (value.sameServiceAddress) {
        return Yup.object();
    }

    if (value.typeOfCustomer === "INDIVIDUAL") {
        return schema.concat(
            Yup.object().shape({
                citizenId: Yup.string()
                    .matches(/^[0-9]+$/, Messages.mustBeOnlyDigit)
                    .min(13, Messages.mustBe13Character)
                    .max(13, Messages.mustBe13Character)
                    .required("Required field!"),
            }) as any
        );
    }

    if (value.typeOfCustomer === "BUSINESS") {
        return schema.concat(
            Yup.object().shape({
                taxId: Yup.string()
                    .matches(/^[0-9]+$/, Messages.mustBeOnlyDigit)
                    .min(13, Messages.mustBe13Character)
                    .max(13, Messages.mustBe13Character)
                    .required("Required field!"),
                companyName: Yup.string().required("Required field!"),
                companyPhone: Yup.string()
                    .min(6, "Phone number is too short!")
                    .max(10, "Phone number is too long!")
                    .required("Required field!"),
            }) as any
        );
    }
    return schema;
});

export const CustomerPersonalInfoSchema = Yup.object().shape({
    // avatar: Yup.string().required("Required"),
    title: Yup.string().required("Title is required field!"),
    firstNameTh: Yup.string().required("First name Th is required field!"),
    firstNameEn: Yup.string().required("First name En required field!"),
    lastNameTh: Yup.string().required("Last name Th required field!"),
    lastNameEn: Yup.string().required("Last name En required field!"),
    nickname: Yup.string().required("Nickname En required field!"),
    gender: Yup.string().required("Gender is required field!"),
});

export const CustomerPrivacyInfoSchema = Yup.object().shape({
    nationality: Yup.object().required("Required"),
    citizenId: Yup.string().when(
        "nationality",
        (nationality: ICountry, yup: any) => {
            if (nationality?.primary) {
                return Yup.string()
                    .length(13, "Citizen ID must contain 13 digit characters")
                    .required("Required")
                    .nullable();
            }
            return Yup.string().nullable();
        }
    ) as any,
});

export const CustomerContactSchema = Yup.object().shape({
    phone: Yup.string()
        .min(10, "Phone number is too short!")
        .max(14, "Phone number is too long!")
        .required("Required field!"),
    email: Yup.string().email().nullable(),
    phoneCodeCountry: Yup.object().required("Required"),
});

export const CustomerFamilyInfoSchema = Yup.object().shape({
    familyInformation: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required("Required field!"),
            relationship: Yup.string().required("Required field!"),
            dateOfBirth: Yup.string().required("Required field!"),
            phone: Yup.string()
                .min(10, "Phone number is too short!")
                .max(14, "Phone number is too long!")
                .required("Required field!"),
        }) as any
    ),
});

export const CustomerEmergencyContactSchema = Yup.object().shape({
    emergencyContact: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required("Required field!"),
            relationship: Yup.string().required("Required field!"),
            residence: Yup.string().required("Required field!"),
            phone: Yup.string()
                .min(10, "Phone number is too short!")
                .max(14, "Phone number is too long!")
                .required("Required field!"),
        }) as any
    ),
});

export const CustomerReferrerSchema = Yup.object().shape({
    salePerson: Yup.object().required(
        "Referrer (Sale Person) is required field!"
    ),
});

export const CustomerSchema = CustomerPersonalInfoSchema.concat(
    CustomerPrivacyInfoSchema as any
)
    .concat(CustomerContactSchema as any)
    .concat(CustomerFamilyInfoSchema as any)
    .concat(CustomerEmergencyContactSchema as any)
    .concat(
        Yup.object().shape({
            branch: Yup.object().required("Required"),
        }) as any
    )
    .concat(CustomerReferrerSchema as any)
    .concat(
        Yup.object().shape({
            allergyHis: Yup.string().required(Messages.allergyHisRequired),
            underDisease: Yup.string().required(Messages.underDiseaseRequired),
        }) as any
    );

export const CustomerNoteSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    content: Yup.string().required("Required"),
});
