const translations: { [key: string]: string } = {
    back: "Back",
    id: "ID",
    role: "Role",
    createAt: "Create At",
    action: "Action",
    allRoles: "All Roles",
    confirm: "Confirm",
    searchCustomer: "Search Customer",
    assignPic: "Assign PIC",
    search: "Search",
    personalInfo: "Personal information",
    password: "Password",
    authentication: "Authentication",
    updateCustomerSuccessfully: "Update customer successfully!",
    resetUserPasswordSuccessfully: "Reset user password successfully!",
    createUserSuccessfully: "Create user successfully!",
    updateUserSuccessfully: "Update user successfully!",
    sendResetPassword: "Send reset password",
    userDetail: "User detail",
    status: "Status",
    userGoogleToScanThisCode:
        "Use Google Authentication to scan this QR Code for the 2-step verification. Please make sure to keep the secret code confidentially. In case you sent this QR Code  for user to scan, please make sure to unsend/delete it upon the completion.",
    generate: "Generate",
    secretCode: "Secret code",
    regenerate: "Regenerate",
    qrCode: "QR code",
    newPassword: "New password",
    newPasswordConfirm: "New password confirm",
    updateRole: "Update role",
    createRoleSuccess: "Create role successfully!",
    updateRoleSuccess: "Update role successfully!",
    general: "General",
    name: "Name",
    updateRoleResource: "Update role resource",
    access: "Access",
    allUsers: "All users",
    avatar: "Avatar",
    email: "Email",
    companyId: "Company ID",
    updatedDate: "Updated date",
    all: "All",
    unRead: "Unread",
    notification: "Notification",
    markAllAsRead: "Mark all as read",
    userCreate: "New user",
    userRoles: "User roles",
    customerTable: "Customer table",
    customerCreate: "Customer create",
    customers: "Customers",
    allCustomer: "All customer",
    customerNo: "Customer No.",
    age: "Age",
    totalPurchase: "Total purchase",
    newCustomer: "New customer",
    branchWarehouse: "Branch/Warehouse",
    branchWarehouseCode: "Branch/Warehouse Code",
    approved: "Approved",
    approvedAt: "Approved at",
    approvedBy: "Approved by",
    approvalRemark: "Approval remark",
    emergencyContact: "Emergency contact",
    familyInfo: "Family information",
    contactInfo: "Contact information",
    privacyInfo: "Privacy information",
    title: "Title",
    firstNameTh: "First Name (Thai)",
    firstNameEn: "First Name (Eng)",
    lastNameTh: "Last Name (Thai)",
    lastNameEn: "Last Name (Eng)",
    firstName: "First Name",
    lastName: "Last Name",
    username: "User Name",
    dateOfBirth: "Date of birth",
    addMore: "Add more",
    preferNotToSay: "Prefer not to say",
    male: "Male",
    female: "Female",
    nickname: "Nickname",
    nickName: "Nickname",
    gender: "Gender",
    passportExp: "Passport Exp.",
    citizenId: "Citizen Id",
    nationality: "Nationality",
    passportNo: "Passport No.",
    religion: "Religion",
    maritalStatus: "Marital Status",
    numberOfChild: "Number of Child",
    primaryPhone: "Primary Phone No.",
    alternativePhoneNo: "Alternative Phone No.",
    alternativePhone: "Alternative Phone No.",
    facebook: "Facebook",
    instagram: "Instagram",
    line: "Line",
    google: "Google",
    lineId: "Line ID",
    addNew: "Add new",
    phone: "Phone",
    relationship: "Relationship",
    currentAddress: "Current address",
    upload: "Upload",
    dashboard: "Dashboard",
    doctorOrder: "Doctor order",
    orderHistory: "Order History",
    quotationHistory: "Quotation History",
    shippingInfo: "Shipping information",
    billingInfo: "Billing information",
    notes: "Notes",
    tickets: "Tickets",
    settings: "Settings",
    jobType: "Job Type",
    orderStatusManagement: "Order Status Management",
    deliveryStatusManagement: "Delivery Status Management",
    normalEmployee: "Normal Employee",
    doctor: "Doctor",
    therapist: "Therapist",
    nurse: "Nurse",
    driver: "Driver",
    pendingPayment: "Pending Payment",
    pendingConfirmation: "Pending Confirmation",
    partiallyPaid: "Partially Paid",
    orderProcessing: "Order Processing",
    deliveryProcessing: "Delivery Processing",
    materialPurchaseProcessing: "Material Purchase Processing",
    delivered: "Delivered",
    completed: "Completed",
    cancelled: "Cancelled",
    rejected: "Rejected",
    refunded: "Refunded",
    deliveryPending: "Delivery Pending",
    deliveryBooked: "Delivery Booked",
    inDelivery: "In-Delivery",
    deliveryCompleted: "Delivery Completed",
    deliveryCancelled: "Delivery Cancelled",
    deliveryReturned: "Delivery Returned",
    users: "Users",
    userTable: "User list",
    totalCourseUsage: "Total course usage",
    pleaseSearchAndSelect: "Please search and select",
    add: "Add",
    joinSince: "Join since",
    lastUpdated: "Last updated",
    createdAt: "Created at",
    heightCm: "Height (cm)",
    weightKg: "Weight (kg)",
    bloodGroup: "Blood group",
    bloodPressure: "Blood pressure (mm.Hg.)",
    phoneNo: "Phone No.",
    otherPhoneNo: "Other phone No.",
    tags: "Tags",
    customerStatic: "Customer statics",
    lifetimeSpending: "Lifetime spending",
    lifeTimePurchase: "Lifetime purchase",
    averageTimeBetweenOrder: "Average time between order",
    averageOrderValue: "Average order value",
    lastPurchase: "Last purchase",
    lastActive: "Last active",
    recentMedicalHistory: "Recent medical history",
    recentPurchaseHistory: "Recent purchase history",
    odpId: "ODP ID",
    odpStatus: "ODP Status",
    totalValue: "Total value",
    orderId: "Order ID",
    orderStatus: "Order status",
    activities: "Activities",
    medicalGeneralProfile: "Medical general profile",
    allergyHistoryAnd: "Allergy history and underlying disease",
    surgeryHistory: "Surgery history",
    medicalProfile: "Medical profile",
    underlyingDisease: "Underlying disease",
    allergyHistory: "Allergy history",
    admissionHospital: "Admission hospital",
    surgery: "Surgery",
    date: "Date",
    employeeId: "Employee Id",
    enabled: "Enabled",
    disabled: "Disabled",
    lastLogin: "Last Login",
    updatedAt: "Updated At",
    defaultWorkingSchedule: "Default Working Schedule",
    specialAccess: "Special Access",
    fullName: "Full Name",
    downloadPdf: "Download Pdf",
    export: "Export",
    edit: "Edit",
    workInfinityXAdvancedAuthentication: "Advanced Authentication",
    exportAuthenDescription:
        "Please go to App Store (iOS) or Play Store (Android) and download application 'Google Authenticator'. Then open the app and scan the QR Code below to get the 6-digit verification code for the 2nd-step login.",
    exportAuthenFooter:
        "For your own account security, please be advised to permanently delete this QR Code after successfully scanning and remember not to send anyone this QR Code.",
    employeedId: "Employee Id",
    specialAccessDescription:
        "Use this access key as a special password to bypass the login account of this user.",
    usageType: "Usage Type",
    oneTimeUse: "One-time use",
    multipleTimeUse: "Multiple-time use",
    code: "Code",
    expiredAt: "Expired At",
    expiresAt: "Expires At",
    updateCustomerTagSuccess: "Update customer tags successfully",
    addCommentSuccess: "Add comment successfully",
    cancel: "Cancel",
    save: "Save",
    updateCustomerSuccess: "Update customer successfully",
    remove: "Remove",
    residence: "Residence",
    noReligion: "No religion",
    buddists: "Buddists",
    christians: "Christians",
    hindus: "Hindus",
    muslims: "Muslims",
    single: "Single",
    married: "Married",
    divorced: "Divorced",
    areYouSureWantRemoveBranch: "Are you sure you want to remove this branch",
    branch: "Branch",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    start: "Start",
    end: "End",
    confirmDelete:
        "You are going to delete this item. Check carefully and confirm one more time to make sure of your action. Please note that this action cannot be undone.",
    addressInfo: "Address information",
    new: "New",
    typeOfCustomer: "Type of Customer",
    companyName: "Company name",
    companyTaxId: "Company tax ID",
    companyPhoneNo: "Company phone No.",
    phoneNumber: "Phone number",
    typeOfPlace: "Type of place",
    addressHouseNo: "Address (House No., Road, Sub-district.)",
    province: "Province",
    postcode: "Postcode",
    district: "District",
    subDistrict: "Sub-district",
    nameOfPlace: "Name of place",
    remark: "Remark",
    address: "Address",
    delete: "Delete",
    setAsDefault: "Set as default",
    createCustomerAddressSuccess: "Create customer address successfully",
    updateCustomerAddressSuccess: "Update customer address successfully",
    createCustomerSuccess: "Create customer successfully",
    default: "Default",
    areYouSureWantRemove:
        "Are you sure you want to remove this? Please note that your action cannot be undone!",
    removeSuccess: "Removing successfully",
    mustBe13Character: "Must be 13 characters!",
    mustBeOnlyDigit: "Must be only digits!",
    billingAddress: "Billing Address",
    typeCustomer: "Type of customer",
    taxId: "Tax ID",
    deleteAddressSuccess: "Delete address successfully!",
    areYouSureDeleteAddress:
        "Are you sure want to delete this address? Please keep in mind that this action can not be undone !",
    recentCaseStudy: "Recent case study",
    caseId: "Case ID",
    service: "Service",
    starting: "Starting",
    ending: "Ending",
    doctorOrderId: "Doctor order ID",
    subject: "Subject",
    survey: "Survey",
    surveyCompletedAt: "Survey completed at",
    update: "Update",
    roles: "Roles",
    preset: "Preset",
    admin: "Admin",
    custom: "Custom",
    generalInformation: "General Information",
    accessConfiguration: "Access Configuration",
    common: "Common",
    product: "Product",
    user: "User",
    updateSpecialAccess: "Update Special Access",
    customer: "Customer",
    list: "List",
    detail: "Detail",
    create: "Create",
    updatePersonal: "Update Personal",
    updateMedical: "Update Medical",
    updateAddress: "Update Address",
    updateBilling: "Update Billing",
    createAddress: "Create Address",
    createBilling: "Create Billing",
    removeAddress: "Remove Address",
    removeBilling: "Remove Billing",
    addressDetail: "Address Detail",
    addressAll: "Address",
    billingAll: "Billing",
    billingDetail: "Billing Detail",
    createNote: "Create Note",
    updateNote: "Update Note",
    removeNote: "Remove Note",
    listNote: "List Note",
    updateTag: "Update Tag",
    updateSurvey: "Update Survey",
    categoryService: "Category Service",
    categoryProduct: "Category Product",
    categoryEquipment: "Category Equipment",
    equipment: "Equipment",
    temporarySchedule: "Temporary Schedule",
    serviceConfiguration: "Service Configuration",
    updateWarehouse: "Update Warehouse",
    updatePic: "Update Pic",
    mediaUpload: "Media Upload",
    getUrl: "Get Url",
    mediaFile: "Media File",
    mediaFolder: "Media Folder",
    cartRule: "Cart Rule",
    quotation: "Quotation",
    shipping: "Shipping",
    move: "Move",
    haveAGoodDay: "Have A Good Day,",
    dashboardWelcome:
        "Welcome to Dermaster ERP, a powerful system developed and powered by Lotus Bedding Group. We wish you can maximize the report ability and have a pleasant working day!",
    amount: "Amount",
    type: "Type",
    staff: "Staff",
    voucherNo: "Voucher No",
    treatment: "Treatment",
    effectiveDate: "Effective date",
    expiryDate: "Expiry date",
    voucher: "Voucher",
    createdBy: "Created by",
    note: "Note",
    content: "Content",
    createCustomerNoteSuccess: "Create customer note successfully",
    updateCustomerNoteSuccess: "Update customer note successfully",
    services: "Services",
    serviceCategoryTable: "Service category table",
    serviceCategoryDetail: "Service category detail",
    serviceCategoryCreate: "Service category create",
    description: "Description",
    updateCategorySuccessful: "Update category successfully",
    createCategorySuccessful: "Create category successfully",
    estProcedureTime: "Est. procedure time",
    newConfig: "New config",
    createSuccess: "Create successfully!",
    procedureTime: "Procedure time",
    allBranches: "All branches",
    branchCreate: "New branch",
    branchTable: "Branch list",
    newBranch: "New Branch/Warehouse",
    pickupCode: "Pick-up Code",
    brandTaxId: "Brand Tax Id",
    manualStockAdjustment: "Manual Stock Adjustment",
    allowed: "Allowed",
    notAllowed: "Not Allowed",
    pleaseInput: "Please Input",
    pleaseSelect: "Please Select",
    createBranchSuccessfully: "Create branch successfully!",
    category: "Category",
    jobCost: "Job cost",
    noUpTask: "No. of task",
    activity: "Activity",
    jobCostSetting: "Job cost setting",
    hour: "Hour",
    unit: "Unit",
    quantity: "Quantity",
    updateSuccess: "Update successfully",
    cancelSuccess: "Cancel successfully",
    addSuccess: "Add successfully",
    time: "Time",
    task: "Task",
    warehouseDelivery: "Warehouse Delivery",
    addBranch: "Add branch/warehouse",
    productCategory: "Product category",
    products: "Products",
    productTable: "Product table",
    allProduct: "All product",
    productCreate: "Product create",
    sku: "SKU",
    visibility: "Visibility",
    brand: "Brand",
    isSellOutOfStock: "Continue selling when out-of-stock",
    weight: "Weight",
    regularPrice: "Regular price",
    salePrice: "Sale price",
    mediaManager: "Media manager",

    deleteFolder: "Delete folder",

    createFolder: "Create folder",
    defaultAddress: "Default Address",

    country: "Country",
    expandAll: "Expand all",
    collapseAll: "Collapse all",
    deleteCustomer: "Delete Customer",
    areYourSureToDeleteThisFolder: "Are you sure to delete this folder?",
    deleteFolderSuccess: "Delete folder successful!",
    folderName: "Folder name",
    createFolderSuccess: "Create folder success!",
    selectMedias: "Select medias",
    lastModified: "Last modified",
    unSelectAll: "Unselect all",
    deleteAll: "Delete all",
    downloadAll: "Download all",
    addVideoURL: "Add Video URL",
    mediaType: "Media type",
    moveAll: "Move all",
    selected: "Selected",
    areYouSureDeleteMedia: "Are you want to delete this image?",

    sliderProduct: "Slider product",
    uploadMediaSuccess: "Media upload successfully!",
    mediaFiles: "Media files",
    mediaTypeIsRequire: "Media type is require!",
    mediaFilesIsRequire: "Media files is require!",
    deleteMedia: "Delete media",
    areYouSureToDeleteMedia: "Are you sure to delete these medias",
    deleteMediaSuccess: "Delete media successfully",
    updateBranchSuccessfully: "Update branch successfully",
    updatePaymentMethodSuccessfully: "Update payment method successfully",
    pic: "PIC",
    addPic: "Add PIC",
    areYouSureWantRemoveUser: "Are you sure you want to remove this user",

    equipmentCategory: "Equipment Category",
    equipmentCategoryDetail: "Equipment Category Detail",
    equipmentTable: "Equipment Table",
    equipmentDetail: "Equipment Detail",
    newEquipmentCategory: "New Equipment Category",
    createEquipmentCategorySuccessfully:
        "Create Equipment Category Successfully",
    updateEquipmentCategorySuccessfully:
        "Update Equipment Category Successfully",
    createEquipmentSuccessfully: "Create Equipment Successfully",
    updateEquipmentSuccessfully: "Update Equipment Successfully",
    newEquipment: "New Equipment",
    model: "Model",
    serialNo: "Serial No",
    condition: "Condition",
    excellent: "Excellent",
    underMaintenance: "Under Maintenance",
    media: "Media",
    gallery: "Gallery",
    addSelected: "Add selected",
    productType: "Product type",
    simpleProduct: "Simple product",
    serviceProduct: "Service product",
    premiumServiceProduct: "Premium service product",
    groupProduct: "Group product & service",
    specialGroupProduct: "Special group product & service",
    bundleProduct: "Bundle of simple product & service",
    thisIsBundleProduct: "This is a bundle of simple product and service ",
    thisIsSpecialGroupProduct: "This is a special group product & service",
    thisIsGroupProduct: "This is a normal group product & service",
    thisIsPremiumService: "This is a premium service",
    thisIsService: "This is a service",
    thisIsSimpleProduct: "This is a simple product",
    yes: "Yes",
    no: "No",
    profilePicture: "Profile picture",
    specialGroupService: "Special group service",
    searchProductTable: "Search product table",
    singleUse: "Single use",
    multipleUse: "Multiple use",
    spendingCap: "Spending cap",
    specialGroupProductService: "Special group product service",
    dynamicPrice: "Dynamic price",
    unitPrice: "Unit price",
    item: "Item",
    updatePassword: "Update Password",
    bundle: "Bundle",
    medicineConfig: "Medicine configuration",
    thisIsMedicine: "This is a medicine",
    thisIsNotMedicine: "This is not medicine",
    thisIsNonOperationProduct: "This is a non-operation product/service",
    thisIsOperationProduct: "This is an operation product/service",
    additionalConfiguration: "Additional Configuration",
    scheduleId: "Schedule ID",
    temporaryScheduleCreate: "Temporary schedule create",
    temporaryScheduleTable: "Temporary schedule table",
    allTemporarySchedule: "All temporary schedules",
    newSchedule: "New schedule",
    doctorNo: "Doctor No.",
    lastOpd: "Last OPD",
    temporaryScheduleDetail: "Temporary schedule detail",
    from: "From",
    to: "To",
    detailOfSchedule: "Detail of schedule",
    productConfig: "Product configuration",
    groupProductConfig: "Group product config",
    bundleProductConfig: "Bundle product config",

    typeOfTreatment: "Type Of Treatment",
    purchaseTypes: "Purchase Type",
    aesthetic: "Aesthetic",
    normal: "Normal",
    categories: "Categories",
    searchYourExistingCustomer: "Search Your Existing Customer",
    customerId: "Customer Id",
    patientType: "Patient Type",
    createGuest: "Create Guest",
    guest: "Guest",
    collapse: "Collapse",
    expand: "Expand",
    generateQuotation: "Generate Quotation",
    customProduct: "Custom Product",
    discount: "Discount",
    openingPrice: "Opening Price",
    total: "Total",
    subTotal: "Sub Total",
    itemAddedSuccessfully: "Item added to cart successfully",
    quotationTable: "Quotation List",
    newQuotation: "New Quotation",
    youHaveToChooseProductsFirst: "You have to choose products first!",
    createQuotationSuccessfull: "Create Quotation Successfully",
    updateQuotationSuccessfull: "Update Quotation Successfully",
    AESTHETIC: "Aesthetic",
    SURGERY: "Surgery",
    active: "Active",
    bookings: "Bookings",
    bookingTable: "Booking table",
    bookingCreate: "Booking create",
    estDateOfVisit: "Est. date of visit",
    timeOfVisit: "Time of visit",
    estTimeOfVisit: "Est. time of visit",
    bookingNo: "Booking No.",
    NORMAL: "Normal",
    purchaseType: "Purchase Type",
    contactInformation: "Contact Information",
    confirmedDateOfVisit: "Confirmed date of visit",
    confirmedTimeOfVisit: "Confirmed time of visit",
    createDoctorOrder: "Create doctor order",
    print: "Print",
    price: "Price",
    branchDetail: "Branch Detail",
    quotationDetail: "Quotation Detail",
    productCategoryDetail: "Product category detail",
    productCategoryCreate: "Product category create",
    bookingDetail: "Booking detail",
    productDetail: "Product detail",
    customerDetail: "Customer detail",
    serviceConfigurationDetail: "Service configuration detail",
    orderTable: "Order List",
    newOrder: "New Order",
    orderDetail: "Order Detail",
    order: "Order",
    editContact: "Edit Contact",
    quotationUrl: "Quotation URL",
    bookingNote: "Booking note",
    doctorNotWorking: "Doctor not working on selected day",
    availableDoctorTimeSlot: "Available doctor time slots",
    confirmDateOfVisit: "Confirm date of visit",
    shippingInformation: "Shipping Information",
    shippingAddress: "Shipping Address",
    addShippingInformation: "Add Shipping Information",
    pleaseChooseCustomer: "Please choose a customer",
    billingInformation: "Billing Information",
    useSameShipping: "Use same information as Shipping information",
    sameAsShipping: "Same as Shipping address",
    addBillingInformation: "Add Billing Information",
    loadTreatment: "Load Treatment",
    treatmentNo: "Treatment No.",
    pleaseInputTreatmentNo: "Please Input Treatment Number",
    doctorOrderNo: "Doctor Order No.",
    loadDoctorOrder: "Load Doctor Order",
    pleaseInputDoctorOrderNo: "Please Input Doctor Order Number",
    confirmSuccess: "Confirm successfully",
    pleaseInputDoctorOrdertNo: "Please Input Doctor Order Number",
    placeOrder: "Place Order",
    createdOn: "Created On",
    by: "By",
    orderNotes: "Order Notes (For Internal Use Only)",
    dob: "DOB",
    orderContact: "Order Contact",
    citizendId: "Citizend ID",
    doctorOrderReferences: "Doctor Order References",
    quotationReferences: "Quotation References",
    voucherReferences: "Voucher References",
    updateOrderSuccessfully: "Update Order Successfully",
    addQuotationRef: "Add Quotation Ref",
    newBooking: "New booking",
    walkIn: "Walk-in",
    customerSource: "Source of customer",
    calendar: "Calendar",
    dateRange: "Date Range",
    room: "Room",
    opdQuantity: "OPD Quantity",
    thisIsGuestVisit: "This is guest visit",
    guestInfo: "Guest information",
    view: "View",
    filter: "Filter",
    noProductFound: "There is no product in this Doctor order",
    expiresOn: "Expires On",
    changeExpiryDate: "Change Expiry Date",
    voucherTable: "Voucher table",
    voucherCreate: "Voucher create",
    voucherDetail: "Voucher detail",
    newVoucher: "New voucher",
    cashVoucher: "Cash voucher",
    giftVoucher: "Gift voucher",
    manual: "Manual",
    autoGenerate: "Auto generate",
    leaveEmptyForNoExp: "Leave empty for no expiry",
    typeOfIssuance: "Type of issuance",
    usageLimit: "Usage Limit Per Coupon",
    cashValue: "Cash value",
    suffix: "Suffix",
    prefix: "Prefix",
    expiryOn: "Expires on",
    generalInfo: "General information",
    usageStatus: "Usage status",
    ownershipStatus: "Ownership status",
    treatmentRef: "Treatment Ref.",
    usedAt: "Used at",
    usedBy: "Used by",
    areYouSureWantCancel:
        "Are you sure make this action? Please keep in mind that this action can not be undone!",
    customerPayment: "Customer Payment",
    splitPayment: "Split Payment",
    paymentRef: "Payment Ref",
    method: "Method",
    change: "Change",
    payment: "Payment",
    totalCart: "Total Cart",
    splitAmount: "Split Amount",
    remainingAmount: "Remaining Amount",
    splitMore: "Split More",
    pleaseFillAllThePaymentAmount: "Please fill all the payment amount fields!",
    paymentIsNotEnough:
        "The total splitted amount must be same as the total amount!",
    youNeedToChooseASpecificPaymentMethod:
        "You need to choose a specific payment method !",
    totalPaidAmountCannotGreaterThanTotalCart:
        "The total paid amount cannot be greater than the total cart !",
    paymentInformation: "Payment Information",
    paymentMethod: "Payment Method",
    paymentOption: "Payment Option ",
    nameOfPayee: "Name Of Payor",
    bankOfTransfer: "Bank Of Transfer",
    dateOfPayment: "Date Of Payment",
    requireBankTransfer: "Require bank transfer",
    requirePaymentCardDevice: "Require payment card device",
    changePaymentMethod: "Change Payment Method",
    bankTransfer: "Bank Transfer",
    c2pName: "2C2P",
    creditCard: "Credit Card",
    cash: "Cash",
    thaiQRPayment: "Thai QR Payment",
    merchantName: "Merchant Name",
    C2PTransactionId: "2C2P Transaction Id",
    reject: "Reject",
    confirmFullPay: "Confirmation of payment completion",
    confirmRejectPayment: "Confirmation of payment rejection",
    areCertainPaymentPaid:
        "Are you certain that this payment has been fully paid? Please note that this action cannot be undone.",
    areCertainPaymentReject:
        "Are you certain that you want to reject this payment? Please note that this action cannot be undone.",
    actualDatePayment: "Actual Date Payment",
    actualBankReceivePayment: "Actual bank of receiving payment",
    actualBankPaymentIsRequired: "Actual bank payment field is required!",
    actualDatePaymentIsRequired: "Actual date receiving payment is required!",
    remarkIsRequired: "Remark is required",
    confirmBy: "Confirmed by",
    rejectBy: "Rejected By",
    at: "at",
    doctorOrderTable: "Doctor Order List",
    newDoctorOrder: "New Doctor Order",
    doctorOrderDetail: "Doctor Order Detail",
    doctorSubject: "Doctor Subject",
    doctorOpinion: "Doctor Opinion",
    recommendedTreatments: "Recommended Treatments",
    completedTreatmentQty: "Completed Treatment Qty.",
    completedTreatment: "Completed Treatment",
    uncompletedTreatmentQty: "Uncompleted Treatment Qty.",
    addTreatment: "Add Treatment",
    addProduct: "Add Product",
    addProductSuccessfully: "Add Product successfully",
    removeProductSuccessfully: "Remove Product successfully",
    addTreatmentSuccessfully: "Add Treatment successfully",
    removeTreatmentSuccessfully: "Remove Treatment successfully",
    bookingNotFound: "Booking is not valid",
    recommendedTreatmentQty: "Recommended Treatment Qty",
    pending: "Pending",
    processing: "Processing",
    completeDoctorOrder: "Complete Doctor Order",
    doNo: "DO No.",
    recommendedTreatment: "Recommended Treatment",
    ref: "Ref",
    medicine: "Medicine",
    completeDoctorOrderSuccessfully: "Complete Doctor Order successfully",
    cancelDoctorOrderSuccessfully: "Cancel Doctor Order successfully",
    cancelDoctorOrder: "Cancel Doctor Order",
    cannotCancelThisDoctorOrder: "This order is not cancellable",
    inProcess: "In-process",
    treatments: "Treatments",
    treatmentTable: "Treatment table",
    treatmentCreate: "Treatment create",
    allTreatment: "All treatment",
    doctorSlot: "Doctor slot",
    startTreatment: "Start treatment",
    treatmentStartAt: "Treatment start at",
    treatmentEndAt: "Treatment end at",
    dateOfTreatment: "Date of treatment",
    estTimeOfTreatment: "Est. Time Of Treatment",
    treatmentProgress: "Treatment progress",
    material: "Material",
    booking: "Booking",
    createDelivery: "Create Delivery",
    deliveryType: "Delivery Type",
    vehicleNo: "Vehicle No.",
    deliveryNo: "Delivery No.",
    estDateOfArrival: "Est. Date Of Arrival",
    willBeLeft: " will be left",
    createOrderSuccessfully: "Create Order successfully",
    createDeliverySuccessfully: "Create Delivery successfully",
    deleteTrackingNumber: "Delete Tracking Number",
    deleteTrackingNumberDes:
        "Are you sure want to delete this tracking number? Please note that this action cannot be undone.",
    copySuccess: "Copied to clipboard",
    hasNoTrackingInfo: "No Tracking Info",
    warehouse: "Warehouse",
    estimatedOn: "Estimated to arrive on ",
    arrivedOn: "Arrived on",
    trackPackage: "Track Package",
    printShippopLabel: "Print Shippop Label",
    listItem: "List Item",
    updateStatus: "Update Status",
    deliveryTracking: "Delivery Tracking",
    deliveryAssignment: "Delivery Assignment",
    truck: "Truck",
    shippop: "Shippop",
    ems: "EMS",
    dhl: "DHL",
    allDeliveries: "All Deliveries",
    deliveryService: "Delivery Service",
    updateDelivery: "Update Delivery",
    canNotSelectInterruptSlots: "Can not select interrupt time slots",
    userNotWorking: "Selected user not working on selected day",
    deliveryStatus: "Delivery Status",
    bookedSchedule: "Booked schedule",
    loginWithWorkInfinityX: "Login With Work Infinity X",
    orLoginWith: "Or Login With",
    loginWithUsernamePassword: "Username & Password",
    reMap: "Remap",
    map: "Map",
    mapped: "Mapped",
    mapProduct: "Map Product",
    unSelected: "Unselected",
    pleaseSelectProductChannelFirst: "Please select branch first",
    pleaseAddProductToCartFirst: "Please add product to cart first",
    voucherCode: "Voucher Code",
    updateDeliveryStatusSuccessfully: "Update Delivery Status Successfully",
    hide: "Hide",
    equipmentNotAvailable: "Equipment not available",
    beforeAfter: "Before & after",
    before: "Before",
    after: "After",
    recordedAt: "Record at",
    treatmentStart: "Treatment start",
    completeTreatment: "Complete treatment",
    scheduledTreatmentTime: "Schedule treatment time",
    actualTreatmentTime: "Actual treatment time",
    createTreatment: "Create treatment",
    appointmentTable: "Appointment table",
    appointmentCreate: "Appointment create",
    appointmentDetail: "Appointment detail",
    appointment: "Appointment",
    allAppointment: "All appointment",
    appointmentNo: "Appointment No.",
    createBooking: "Create booking",
    serviceDescription: "Service Description",
    duplicate: "Duplicate",
    hours: "Hours",
    minutes: "Minutes",
    priority: "Priority",
    paymentMethodDetail: "Payment Method Detail",
    merchantId: "Merchant ID",
    secretKey: "Secret Key",
    mode: "Mode",
    addressInformation: "Address Information",
    full: "Full",
    bank: "Bank",
    cc: "Credit Card",
    ipp: "IPP",
    otc: "OTC",
    q: "Q",
    alipay: "Alipay",
    wallet: "Wallet",
    method2C2P: "2C2P Methods",
    bankAccounts: "Bank Accounts",
    bankName: "Bank Name",
    bankLogo: "Bank Logo",
    accountName: "Account Name",
    accountNumber: "Account Number",
    accountNo: "Account No.",
    payments: "Payments",
    "condo-apartment": "Condominium/Apartment",
    "private-house": "Private House",
    "company-office": "Company/Office",
    condominium: "Condominium/Apartment",
    privateHouse: "Private House",
    companyOffice: "Company/Office",
    bookingHistory: "Booking History",
    orderUrl: "Order Url",
    recentBookings: "Recent Bookings",
    recentDoctorOrders: "Recent Doctor Orders",
    recentOrders: "Recent Orders",
    recentQuotations: "Recent Quotations",
    voucherType: "Voucher Type",
    usage: "Usage",
    scheduled: "Scheduled",
    recordAt: "Record At",
    never: "Never",
    neverExpires: "Never expires",
    available: "Available",
    used: "Used",
    expiring: "Expiring",
    expired: "Expired",
    owned: "Owned",
    orderRef: "Order Ref",
    visitProfile: "Visit Profile",
    confirmed: "Confirmed",
    system: "System",
    viewAll: "View All",
    newRegistration: "New Registration",
    requiredField: "This is a required field",
    treatmentHistory: "Treatment History",
    recentTreatments: "Recent Treatments",
    dynamicItemPriceNoLess0: "Dynamic item price can not less then 0 ",
    invalidVoucher: "This voucher is invalid",
    giftVoucherConfiguration: "Gift voucher configuration",
    selectGenerateVoucher: "Select to generate gift voucher (optional)",
    expiredAfterYear: "Number Of Years Till Expiry Date",
    expiredAfterMonth: "Number Of Months Till Expiry Date",
    expiredAfterDay: "Number Of Days Till Expiry Date",
    enableGenerateGift: "Enabled to generate gift voucher",
    disableGenerateGift: "Disabled to generate gift voucher",
    neverExpire: "Never expires",
    expireInYearMonthDay: "Expires in %year years %month months %day days",
    applyVoucher: "Apply Voucher",
    autoSelectedToApply: "Auto-selected to apply",
    customerAllVouchers: "Customer All Vouchers",
    guestVouchers: "Guest Vouchers",
    balance: "Balance",
    transferVoucher: "Transfer voucher",
    transferVoucherTable: "Transfer voucher table",
    transferVoucherCreate: "Transfer voucher create",
    transferVoucherDetail: "Transfer voucher detail",
    allTransferVoucher: "All transfer voucher",
    fromCustomer: "From customer",
    toCustomer: "To customer",
    codeIsExpired: "Code is expired !",
    profileUpdateRequest: "Profile Update Request",
    enableSelfRegistration: "Enable Self-Registration Form",
    successfullyEnableSelfRegistration:
        "Successfully Enable Self-Registration Form!",
    thisQRCodeIsScheduled:
        "This QR code is scheduled to expire in $inTime (at $time)",
    thisCustomerJustMakeAnModification:
        "This customer just made an modification to their profile. Please double-check with the customer. Once approved, this information will become official.",
    orderHasUncompletedTreatment:
        "Attention! This doctor order {id} contains uncompleted treatment. Please make sure to recheck and take appropriate action first before creating sales order.",
    timeSlot: "Time slot",
    initialSpendingCap: "Initial spending cap",
    balanceAmount: "Balance amount",
    multiple: "Multiple",
    approve: "Approve",
    customerAddress: "Customer Address",
    usedAmount: "Used Amount",
    submittedDate: "Submitted Date",
    generateGiftVoucher: "Generate Gift Voucher",
    taxIncluded: "Tax (7% Included)",
    generatedGiftVoucher: "Generated Gift Voucher",
    areYouSureWantApproveThisCustomerInfo:
        "Are You Sure Want To Approve This Update Customer Information ?",
    regenerateCode: "Regenerate Code",
    changeExpiryTime: "Change Expiry Time",
    sendSms: "Send Sms",
    sendEmail: "Send Email",
    expiryTime: "Expiry Time",
    verify: "Verify",
    verified: "Verified",
    unVerified: "Unverified",
    assessmentForm: "Assessment Form",
    generateQrCode: "Generate Qr Code",
    assessmentCompletedAt: "Assessment completed at",
    healthAssessmentForm: "Health Self-assessment Form",
    areYouSureWantVerifyThisCustomer:
        "Are you sure want to confirm all information of this customer is verified ?",
    clientSignature: "Client's Signature",
    witnessSignature: "Witness's Signature",
    signedOn: "Signed on",
    cancelOrder: "Cancel Order",
    copiedToClipboard: "Copied to clipboard !",
    items: "Items",
    currentQty: "Current Quantity",
    attachment: "Attachment",
    inventory: "Inventory",
    inventoryList: "Inventory List",
    stockAdjustmentRequest: "Stock Adjustment",
    newStockAdjustment: "New Stock Adjustment",
    stockAdjustmentDetail: "Stock Adjustment Detail",
    addFile: "Add file",
    allBooking: "All Bookings",
    cancelRequest: "Cancel Request",
    requestStockFrom: "Request Stock From",
    requestStockTo: "Request Stock To",
    orderNo: "Order No.",
    allStockAdjustmentRequest: "All Stock Adjustments",
    stockRequest: "Stock Requests",
    allStockRequest: "All Stock Requests",
    allStockTransfer: "All Stock Transfers",
    stockRequestTable: "Stock Request",
    stockRequestCreate: "New Stock Request",
    stockRequestDetail: "Stock Request Detail",
    stockTransferTable: "Stock Transfer",
    stockTransferCreate: "New Stock Transfer",
    stockTransferDetail: "Stock Transfer Detail",
    expectedDateOfArrival: "Expected date of arrival",
    expectedDateOfTransfer: "Expected date of transfer",
    expectedTransferDate: "Expected transfer date",
    generatedVouchers: "Generated Vouchers",
    productFromSO: "Product From SO",
    clearAll: "Clear all",
    origin: "Origin",
    partial: "Partial",
    transferred: "Transferred",
    destination: "Destination",
    requestedQty: "Requested quantity",
    transferQty: "Transfer quantity",
    transferType: "Transfer type",
    cancelConfirmation: "Cancellation Confirmation",
    approvalConfirmation: "Approval Confirmation",
    returningGoods: "Returning Goods",
    stockIn: "Stock In",
    proofs: "Proofs",
    deliveryDriver: "Delivery Driver",
    deliveries: "Deliveries",
    supervisor: "Supervisor",
    deliveryDriverDetail: "Delivery Driver Detail",
    confirmReceiveAll: "Confirm to receive all",
    inHouse: "In House",
    outSource: "Out Source",
    signNow: "Sign now",
    complete: "Complete",
    signature: "Signature",
    confirmation: "Confirmation",
    assignedTo: "Assigned to",
    assignedDate: "Assigned date",
    pickupStaff: "Pick-up Staff",
    receiveStock: "Receive stock",
    branchWarehouseOrigin: "Branch/Warehouse Origin",
    originPickupConfirmation: "Origin Pick-up Confirmation",
    destinationPickupConfirmation:
        "Branch/Warehouse Staff Pick-up Confirmation",
    branchWarehouseDestination: "Branch/Warehouse Destination",
    receiverConfirmDes: "confirmed that received all following items",
    giverConfirmDes: "confirmed that sent all following items",
    receiveExtraStock:
        "Do you want to receive any extra item which is not in your receiving list?",
    cancelRequestedConfirmationDes:
        "You are going to cancel this request. Check carefully and confirm one more time to make sure of your action. Please note that this action cannot be undone.",
    approvalRequestedConfirmationDes:
        "You are going to approve this request. Check carefully and confirm one more time to make sure of your action. Please note that this action cannot be undone.",
    clickHere: "Click here",
    history: "History",
    totalQty: "Total Qty.",
    onHoldQty: "On-hold Qty.",
    totalOnHoldQty: "Total On-hold Qty.",
    ordered: "Ordered",
    adjusted: "Adjusted",
    initialTransferred: "Initial Transferred",
    driverPickedUp: "Driver Picked Up",
    staffReceived: "Staff Received",
    listItems: "List Items",
    signedBy: "Signed by",
    itemsOffTheList: "Items (Off the list)",
    equipmentInventory: "Equipment Inventory",
    equipmentInventoryList: "Equipment Inventory List",
    equipmentStockAdjustmentRequest: "Equipment Stock Adjustment",
    equipmentStockAdjustmentCreate: "New Equipment Stock Adjustment",
    equipmentStockAdjustmentDetail: "Stock Adjustment Detail",
    allEquipmentStockAdjustmentRequest: "All Equipment Stock Adjustments",
    equipmentStockRequest: "Equipment Stock Requests",
    allEquipmentStockRequest: "All Equipment Stock Requests",
    allEquipmentStockTransfer: "All Equipment Stock Transfers",
    equipmentStockRequestTable: "Equipment Stock Request",
    equipmentStockRequestCreate: "New Equipment Stock Request",
    equipmentStockRequestDetail: "Equipment Stock Request Detail",
    equipmentStockTransferTable: "Equipment Stock Transfer",
    equipmentStockTransferCreate: "New Equipment Stock Transfer",
    equipmentStockTransferDetail: "Equipment Stock Transfer Detail",
    addStock: "Add Stock",
    removeStock: "Remove Stock",
    addHold: "Add Hold",
    removeHold: "Remove Hold",
    holdQTy: "Hold Qty.",
    sendOtp: "Send OTP",
    phoneNumberVerification: "Phone Number Verification",
    verifyPhoneNumberDescription:
        "In order to verify your phone number, a 6-digit verification code will be sent to this phone number @phone",
    inputOtpCodeDescription:
        "Please input the 6-digit verification code sent to your phone number @phone",
    appliedOn: "Applied on",
    readCardData: "Read Card Data",
    scanIdCard: "Scan ID Card",
    noCardReader: "No Card Reader",
    moreActions: "More Actions",
    addAdditionalFee: "Add additional fee",
    addCoSalePerson: "Add a co-sale person",
    salePerson: "Sale Person",
    coSalePerson: "Co-salesperson",
    emptySearchCustomerNote:
        "Ops! There is no any customer matching your search.",
    orScanCustomerIdCard: "Or Scan Customer ID Card",
    newCustomerProfile: "New Customer Profile",
    firstTimeAtDermaster: "First time at Dermaster ?",
    searchForCustomer: "Search for customer (CN, Name, Mobile, ID card number)",
    agencyManagement: "Agency Management",
    newAgency: "New Agency",
    agencyDetail: "Agency Detail",
    agency: "Agency",
    agencyCode: "Agency Code",
    agencyId: "Agency ID",
    business: "Business",
    individual: "Individual",
    tourGuide: "Tour Guide",
    searchEquipment: "Search Equipment",
    addDiscount: "Add Discount",
    discountForASpecificSku: "Discount for a specific SKU",
    discountForWholeCart: "Discount for whole cart",
    typeOfAgency: "Type Of Agency",
    typeOfIdentity: "Type Of Identity",
    individualName: "Individual Name",
    countryOfOrigin: "Country Of Origin",
    companyRegistrationNo: "Company Registration No.",
    residenceAddress: "Residence Address",
    companyAddress: "Company Address",
    manualDiscount: "Manual Discount",
    wholeCart: "Whole Cart",
    group: "Group",
    thaiNationality: "Thai Nationality",
    foreigner: "Foreigner",
    priceConfig: "Pricing Configuration",
    jobCostTemplate: "Job Cost Template",
    jobCostPreset: "Job Cost Preset",
    JcTemplate_1: "JC 1 (Aesthetic + Anti-aging + Others)",
    JcTemplate_2: "JC 2 (Surgery)",
    JcTemplate_3: "JC 3 (Hair Transplant)",
    representativesName: "Representative's Name",
    representativesPrimaryPhone: "Representative's Phone No.",
    representativesAlternatePhone: "Representative's Alternate Phone No.",
    doctorFee: "Doctor Fee",
    therapistFee: "Therapist Fee",
    nurseFee: "Nurse Fee",
    anesthesiologistFee: "Anesthesiologist Fee",
    assistantFee: "Assistant Fee",
    assistantScrubFee: "Assistant Scrub Fee",
    assistantAnesthesiologistFee: "Assistant Anesthesiologist Fee",
    aidNurseFee: "Aid Nurse Fee",
    circulateNurseFee: "Circulate Nurse Fee",
    practitionerNurseFee: "Junior Nurse Fee",
    recoveryRoomNurseFee: "Recovery Room Nurse Fee",
    registerNurseFee: "Register Nurse Fee",
    scrubNurseFee: "Scrub Nurse Fee",
    staffFee: "Staff Fee",
    marketing: "Marketing",
    complication: "Complication",
    vip: "VIP",
    primaryFee: "Primary Fee",
    additionalFee: "Additional Fee",
    unitQty: "Unit Qty.",
    night: "Night",
    select: "Select",
    piece: "Piece",
    jobCostRole: "Job Cost Role",
    payee: "Payee",
    summary: "Summary",
    setting: "Setting",
    vipGroup: "Vip Group",
    employee: "Employee",
    noAgency: "No Agency",
    defaultSalePrice: "Default Sale Price",
    jobCostAssessment: "Job Cost Assessment",
    manualJobCostAssessment: "Manual Job Cost Assessment",
    manualJobCostAssessmentImport: "Manual Job Cost Assessment Import",
    unitOfAdditionalFee: "Unit Of Additional Fee",
    amountOfAdditionalFee: "Amount Of Additional Fee",
    unitQtyOfAdditionalFee: "Unit Qty. Of Additional Fee",
    phoneAlreadyExistError:
        "Attention! This phone number already exists in the system. Please check with customer to make sure it belongs to them. If it's correct, please select it.",
    productConfiguration: "Product Configuration",
    giftVoucherExpiryDate: "Gift Voucher Expiry Date",
    thisItemOutOfStock: "This item is out of stock !",
    jobRoleInTheTreatment: "Job Role In The Treatment",
    errorSelectCustomerStaff:
        "Selected customer is not in the  company's active staff list !",
    errorSelectCustomerVip: "Selected customer is not in the  VIP group!",
    confirmCompleteDoctorOrder:
        "Are you certain that you would like to complete this doctor order? Please be advised that you won’t be able to create any new treatment for this doctor order after this and this action cannot be undone.",
    updateSetting: "Update Setting",
    thisTreatmentDoesNotRequireDoctor:
        "This treatment does not require a doctor",
    none: "None",
    la: "LA",
    laSurgery: "LA Surgery",
    ga: "GA",
    gaSurgery: "GA Surgery",
    surgeryType: "Surgery Type",
    completedQty: "Completed Qty.",
    complicationTreatment: "Complication Treatment",
    newComplicationQty: "New Complication Qty.",
    thisIsComplicationCase: "This is complication case",
    loadPreviousTreatment: "Load Previous Treatment",
    complicationCaseOf: "Complication Case Of",
    youHaveToChooseAtLeastOneDoctorOrderForComplicationCase:
        "You have to choose at lease one Doctor Order for complication case !",
    materialCategory: "Material Category",
    materialCategoryCreate: "Create Material Category",
    materialCategoryDetail: "Material Category Detail",
    materialCreate: "Create Material",
    materialDetail: "Material Detail",
    purchaseUnit: "Purchase Unit",
    trackingUnit: "Tracking Unit",
    purchaseToTrackingConversion: "Purchase to Tracking Unit Conversion",
    lowStockQuantity: "Low stock quantity",
    notifyWhenTrackingUnitEqual: "Notify when tracking unit quantity = ",
    preferredVendor: "Preferred Vendor",
    materialInventory: "Material inventory",
    materialInventoryTable: "Material inventory table",
    trackingUnitOnHand: "Tracking Unit On-hand Qty.",
    trackingUnitOnHandCurrentWh: "Tracking Unit On-hand Qty. (Current WH)",
    trackingUnitOnHandAllWh: "Tracking Unit On-hand Qty. (All WH)",
    trackingUnitOnOrder: "Tracking Unit On-order Qty.",
    unitCost: "Unit cost",
    totalUnitValue: "Total unit value",
    purchasingUnit: "Purchasing Unit",
    unitConversionRate: "Unit Conversion Rate",
    avgPurchasingUnitCost: "Avg. purchasing unit cost",
    avgTrackingUnitCost: "Avg. tracking unit cost",
    onOrderQty: "On-order Qty.",
    purchases: "Purchases",
    adjustments: "Adjustments",
    poNo: "PO no.",
    purchasingUnitCost: "Purchasing unit cost",
    purchasingUnitQty: "Purchasing unit qty.",
    totalPurchasingCost: "Total purchasing cost",
    vendor: "Vendor",
    lotNo: "LOT no.",
    adjustmentDate: "Adjustment Date",
    reason: "Reason",
    qtyDifference: "Qty. Difference",
    qtyChange: "Qty. Change",
    inventoriable: "Inventoriable",
    materialAdjustmentRequest: "Material adjustment request",
    materialAdjustmentRequestCreate: "Material adjustment request create",
    "differentRate%": "Difference rate (%)",
    avgDiffRate: "Avg. Difference Rate",
    materialPreset: "Material preset",
    bottle: "Bottle",
    ml: "ml",
    vendorManagement: "Vendor management",
    vendorTable: "Vendor table",
    vendorId: "Vendor ID",
    qtyOfPO: "Qty. of PO",
    companyRegNo: "Company Reg. No",
    representativeFirstName: "Representative’s First Name",
    representativeLastName: "Representative’s Last Name",
    representativeEmail: "Representative email",
    representativePhoneNo: "Representative’s Phone No.",
    vendorProfilePicture: "Vendor profile picture",
    creditTerm: " Credit term",
    purchase: "Purchase",
    updateSuccessfully: "Update successfully!",
    createSuccessfully: "Create successfully!",
    adjustment: "Adjustment",
    adjustmentQty: "Adjustment Qty.",
    differentRate: "Different rate",
    newPurchaseOrder: "New Purchase Order",
    newMaterialRequest: "New Material Request",
    materialRequest: "Material Request",
    materialRequestDetail: "Material Request Detail",
    materialItemsList: "Material Items List",
    po: "PO",
    attachments: "Attachments",
    purchaseRequestApproval: "Purchase Request Approval",
    attentionApprovalRequest:
        "Attention! This material purchase request is pending for approval. Please check it carefully.",
    requestQuantity: "Request Quantity",
    vendorName: "Vendor's name",
    totalPrice: "Total Price",
    addQuotation: "Add Quotation",
    areYouSureWantToApproveRequest:
        "Are you sure you want to approve this request?",
    approveSuccessfully: "Approve successfully",
    approvePurchaseRequest: "Approve Purchase Request",
    cancelPurchaseRequest: "Cancel Purchase Request",
    approveQuotation: "Approve Quotation",
    cancelQuotation: "Cancel Quotation",
    approvalStatus: "Approval Status",
    quotationId: "Quotation ID",
    quotationFile: "Quotation File",
    dateOfPurchase: "Date of purchasing",
    materialAdjustmentRequestDetail: "Material adjustment request detail",
    vendorCreate: "Vendor create",
    vendorDetail: "Vendor detail",
    adjustAfterTreatment: "Adjustable Qty. After Treatment",
    poRequest: "PO Request",
    purchaseDate: "Purchase Date",
    avgPurchase: "Avg. Purchase",
    materialOrder: "Material Purchase",
    newMaterialOrder: "New Material Purchase",
    createMaterialOrder: "Create Material Purchase",
    medicinePreset: "Medicine preset",
    avgPurchasePrice: "Avg. Purchase Price",
    poApproval: "PO Approval",
    poPendingApproval:
        "This PO is pending for final approval before sending to designated vendor/supplier. Please double-check and confirm the validity of the PO.",
    areYouSureWantToApprovePO:
        "Are you sure you want to approve this purchase order?",
    approvePO: "Approve Purchase Order",
    cancelPO: "Reject Purchase Order",
    cheque: "Cheque",
    lastPurchasingUnitCost: "Last Purchase Unit Cost",
    lastTrackingUnitCost: "Last Tracking Unit Cost",
    cancelledBy: "Cancelled By",
    cancelledAt: "Cancelled At",
    cancelledRemark: "Cancellation Remark",
    creditTermDays: "Credit term (days)",
    inStock: "In-stock",
    lowStock: "Low stock (Tracking Unit On-hand Qty. < 10)",
    outOfStock: "Out-of-stock",
    receiptNo: "Receipt No.",
    deliveryTime: "Delivery Time",
    receivedBy: "Received By",
    confirmationRemark: "Confirmation Remark",
    confirmedAt: "Confirmed at",
    rejectedAt: "Rejected at",
    podeliveryReceipt: "PO Delivery Receipt",
    materialOrderDetail: "Material Purchase Detail",
    addRepresentative: "Add Representative",
    addContact: "Add Contact",
    vendorContact: "Vendor Contact",
    requestQtt: "Request Qty.",
    flexible: "Flexible",
    defaultTherapistRate: "Default Therapist Rate",
    provideAfterTreatment: "Provide after treatment",
    noUpToInitQty: "No, up to the initial treatment quantity",
    noRegardlessInitQty: "No, regardless the initial treatment quantity",
    yesUpToInitQty: "Yes, up to the initial treatment quantity",
    yesRegardlessInitQty: "Yes, regardless the initial treatment quantity",
    creditNote: "Credit Note",
    BOM: "BOM",
    usedQty: "Used Qty.",
    trackingUnitCost: "Tracking unit cost",
    subtotal: "Subtotal",
    noLeftItem: "No left item to be added",
    treatmentDetail: "Treatment detail",
    soNo: "SO No.",
    complicationCase: "Complication Case",
    treatmentReferences: "Treatment References",
    customerToCustomer: "From Customer To Customer",
    guestToCustomer: "From Guest To Customer",
    scheduledToBePaidOn: "Scheduled to be paid on",
    newTransfer: "New transfer",
    transferVoucherType: "Transfer voucher type",
    noExpired: "No expired",
    transferToCustomer: "Transfer to customer",
    transferFromCustomer: "Transfer from customer",
    requiredUnitQtyTriggeringFee:
        "Required unit qty. for triggering additional fee",
    graft: "Graft",
    orderProduct: "Order's products",
    addonPreset: "Add-on Services Preset",
    addonServices: "Add-on Services",
    assignedToAdjustmentRequest:
        "Currently being assigned in a Material Adjustment Request",
    assignedToRequest: "Currently being assigned in a Material Request",
    fees: "Fees",
    defaultDoctorRate: "Default Doctor Rate",
    defaultNurseRate: "Default Nurse Rate",
    defaultAnesthesiologistRate: "Default Anesthesiologist Rate",
    additionalFeePer: "Additional Fee (Per within each additional 15 minutes)",
    arab: "Arab",
    chinese: "Chinese",
    concierge: "Concierge",
    embassy: "Embassy",
    english: "English",
    hotel: "Hotel",
    other: "Other",
    taxiCooperative: "Taxi Cooperative",
    thai: "Thai",
    stockTransfer: "Stock Transfer",
    equipmentTransfer: "Equipment Transfer",
    serviceName: "Service Name",
    productFilter: "Product Filter",
    therapistFeePrimary: "Therapist Fee (Primary)",
    therapistFeeSub: "Therapist Fee (Supplementary)",
    nurseFeePrimary: "Nurse Fee (Primary)",
    nurseFeeSub: "Nurse Fee (Supplementary)",
    timeStart: "Time Start",
    timeEnd: "Time End",
    invalidTime: "Invalid time",
    thisIsNonVatProduct: "This is non-VAT product/service",
    autoCalculateJobCost: "Auto-calculate Job Cost Assessment",
    manualInputJobCost: "Manual Job Cost Assessment",
    manualImportJobCost: "Manual Job Cost Assessment Import",
    fee: "Fee",
    manualInputAmount: "Manual Input Amount",
    maximumFileSizeIs5MB: "Maximum file size is 5MB",
    dragAndDropYourCSVHere: "Drag and drop your CSV file here",
    orSelectFromYourComputer: "Or select it from your computer",
    notSureHowToStart: "Not sure how to get started?",
    thenEditAndReuploadTheFile: "then edit and re-upload the file",
    fileValidation: "File Validation",
    downloadYourCurrentJobCostTemplate:
        "Download your current Job Cost Template,",
    fileName: "File name",
    validationStatus: "Validation Status",
    downloadReport: "Download report",
    readyToImport: "Ready to import",
    errors: "errors",
    lastWarning: "Last Warning:",
    pleaseCheckCarefully:
        "Please check carefully before hit the button `Import`. Please be aware that this action cannot be undone.",
    PIC: "PIC",
    autoCalculatedAmount: "Auto Calculated Amount",
    updateDoctor: "Update Doctor",
    updatePIC: "Update PIC",
    updateEquipment: "Update Equipment",
    updateService: "Update Service",
    updateMaterial: "Update Material",
    updateMedicine: "Update Medicine",
    updateAddOnService: "Update Add On Service",
    cancelTreatment: "Cancel Treatment",
    createBeforeAndAfter: "Create Before And After",
    updateBeforeAndAfter: "Update Before And After",
    removeBeforeAndAfter: "Remove Before And After",
    autoJobAssessment: "Auto Job Assessment",
    manualJobAssessment: "Manual Job Assessment",
    viewDetailMaterial: "View Detail Material",
    createDetailMaterial: "Create Detail Material",
    referrer: "Referrer",
    referrerSalePerson: "Referrer (Sale Person)",
    referrerAgency: "Referrer (Agency)",
    salesPersons: "Salespersons",
    cosalePersonByDoctor: "Co-salesperson (by Doctor)",
    cosalePersonBySales: "Co-salesperson (by Sales)",
    primarySalesPerson: "Primary Salesperson",
    addCoSales: "Add Co-sales",
    salesPersonExist:
        "This sales person has already been assigned to the product",
    salesPersonExistInOrder:
        "This sales person has already been assigned to a product in this order",
    maximumNumberOfDoctorsIsOne:
        "Sorry! You can select maximum 1 co-salesperson (by Doctor)",
    maximumNumberOfSalesIsTwo:
        "Sorry! You can select maximum 2 co-salespersons (by Sales)",
    typeOfSale: "Type of sales commission",
    tel: "Tel",
    taxIDNo: "Tax ID No.",
    fax: "Fax",
    receipt: "Receipt",
    taxInvoice: "Tax Invoice",
    storeCode: "Store Code",
    staffCode: "Staff Code",
    termCondition: "Terms & Condittions",
    patientsMayReturn:
        "- Patients may return the medicine only if they have experienced adverse reaction to that medication.Please keep this receipt for reference.",
    primarySalePerson: "Primary Sale Person",
    posCardDevice: "POS Card Device",
    defaultCoSalePerson: "Default Co-salesperson",
    configuration: "Configuration",
    equipmentConfiguration: "Equipment configuration",
    simple: "Simple",
    configurable: "Configurable",
    thisIsSimpleEquipment: "This is simple equipment",
    thisIsConfigurableEquipment: "This is configurable equipment",
    image: "Image",
    basicHealthInformation: "Basic health information",
    report: "Report",
    cashierReport: "Cashier report",
    previous: "Previous",
    totalInvoice: "Total invoice",
    settleAmount: "Settle amount",
    unsettleAmount: "Unsettle amount",
    balanceOfInvoice: "Balance of invoice",
    createdDate: "Created date",
    ineligibleForManualDiscount:
        "Sorry! This product is ineligible for manual discount",
    salespersonUserReport: "Salesperson report by user",
    salesperson: "Salesperson",
    reportDate: "Report date",
    totalSaleAmountConfirmed: "Total Sales Amount (Only Confirmed SO)",
    totalPendingAmountConfirm:
        "Total Sales Amount (Incl. Pending Confirmation SO)",
    totalCustomerServed: "Total Customer Served",
    allergyHisRequired: "Allergy history is required",
    underDiseaseRequired: "Underlying disease is required",
    importCustomer: "Import Customer",
    importTools: "Import Tools",
    selectImportMethod: "Import Method",
    selectImportMethodDes:
        "Depending on your purpose, you want to import new user that never ever existed in the system or you just simply want to update information of the existing user? Choose wisely because your import might damage all the healthy users.",
    selectCSVFile: "Select CSV",
    fileValidationAndImport: "File Validation & Import",
    importResult: "Import Result",
    updateCustomer: "Update Customer",
    addNewCustomer: "Add New Customer",
    branchId: "Branch ID",
    birthDay: "Birth Day",
    phoneCodeCountry: "Phone Code Country",
    isVip: "Is VIP",
    height: "Height",
    downloadACSVFile: " Download a CSV file, then edit and upload the file.",
    download: "Download",
    blankCSVTemplate: "Blank CSV Template",
    selectImportMethodDesc:
        "Depending on your purpose, you want to import new user that never ever existed in the system or you just simply want to update information of the existing user? Choose wisely because your import might damage all the healthy users.",
    elementToUpdate: "Which element do you want to update?",
    friendGetFriend: "Friend get friend",
    oldCustomer: "Old customer",
    callIn: "Call-in",
    internet: "Internet",
    magazine: "Magazine",
    partner: "Partner",
    sms: "SMS",
    taxi: "Taxi",
    tiktok: "Tiktok",
    travelThrough: "Travel through",
    tv: "TV",
    website: "Website",
    salespersonMonthlyReport: "Salesperson monthly report",
    month: "Month",
    validateStatus: "Validation Status",
    downloadLog: "Download Report",
    newImport: "New Import",
    customersHaveBeenImportedSuccessfully:
        " customers have been imported successfully",
    customersWereFailedToImport: " customers were failed to import",
    customerSourceReport: "Customer source report",
    doctorSummaryReport: "Doctor summary report",
    doctorDetailReport: "Doctor detail report",
    systemId: "System Id",
    selectVoucher: "Select voucher",
    credits: "Credits",
    creditVoucherId: "Credit voucher ID",
    creditAmount: "Credit amount",
    convertToCreditVoucher: "Convert to credit voucher",
    customerWantChange: "Customer want to change",
    totalAvailability: "Total availability",
    customerAllCredits: "Customer all credits",
    creditVoucher: "Credit voucher",
    creditCardNumber: "Credit Card Number",
    cardNumber: "Card number",
    cardType: "Card type",
    bankNumber: "Bank number",
    paidBy: "Paid by",
    incomeType: "Income type",
    antiAging: "Anti-aging",
    hairVitamins: "Hair vitamins",
    commissionType: "Commission type",
    defaultSaleCommission: "Default sale commission",
    commission: "Commission",
    rate: "Rate",
    promotion: "Promotion",
    promotionTable: "Promotion Table",
    promotionCreate: "Promotion Create",
    promotionDetail: "Promotion Detail",
    draft: "Draft",
    published: "Published",
    branches: "Branches",
    period: "Period",
    memo: "Memo",
    promotionTitle: "Promotion Title",
    promotionMemo: "Promotion Memo",
    newPromotionPricing: "New Promotion Pricing",
    listOfItems: "List Of Items",
    documents: "Documents",
    reset: "Reset",
    bulkImport: "Bulk import",
    addItem: "Add item",
    newSalePrice: "New Sale Price",
    startAt: "Start at",
    endAt: "End at",
    import: "Import",
    attentionErrorPromotion: "Attention! This product is on another promotion",
    productsWereFailedToImport: " products were failed to import",
    importFailed: "Failed to import !",
    importSuccess: "Successfully imported !",
    dragDropOrBrowseFiles: "Drag & Drop or Browse files",
    supportUploadFiles: "Support: jpg, jepg, png and pdf (20MB)",
    searchBySKUName: "Search by SKU & Name...",
    totalCredit: "Total credit",
    transferId: "Transfer ID",
    days: "days",
    onGoing: "On-going",
    ended: "Ended",
    stopThisPromotion: "Stop this promotion",
    promotionId: "Promotion ID",
    stopThisPromotionDes:
        "Are you sure you would like to stop this promotion? Once you stop it, the price will be back to normal. Please note that this action cannot be undone!",
    cashierReportByPayment: "Cashier report by payment",
    convertedToCredit: "Converted To Credit",
    newTreatment: "Create New Treatment",
    reference: "Reference",
    usedDate: "Used date",
    customerSignature: "Customer signature",
    cashier: "Cashier",
    cashierReportByOrder: "Cashier report by order",
    therapistFee2: "Therapist Fee 2",
    therapistFee3: "Therapist Fee 3",
    therapistFee4: "Therapist Fee 4",
    therapistFee5: "Therapist Fee 5",
    therapistFee6: "Therapist Fee 6",
    customerVoucherReport: "Customer voucher report",
    treatmentDailyUsageReport: "Treatment daily usage report",
    jobCostFeature: "Job Cost Feature",
    serviceOfCustomer: "Service of customer",
    saleDate: "Sale date",
    firstUsed: "First used",
    lastUsed: "Last used",
    purchasingPriceSO: "Purchasing price from SO",
    purchasingPriceSOUnit: "Purchasing price from SO/Unit",
    qtyTotal: "Total Qty.",
    qtyOfUse: "Qty. of used times",
    qtyBalance: "Qty. Balance",
    balanceThb: "CV Balance (THB)",
    cancelCustomer: "Cancel customer",
    SO: "SO",
    actualUsed: "Actual used",
    staffName: "Staff name",
    quantityActual: "Quantity (Actual)",
    nurseDetailReport: "Nurse detail report",
    therapistDetailReport: "Therapist detail report",
    nurseSummaryReport: "Nurse summary report",
    therapistSummaryReport: "Therapist summary report",
    cancelBooking: "Cancel booking",
    usedTimes: "Used times",
    usedValues: "Used values",
    notApplicableForGV: "Not applicable for GV",
    notApplicableForCV: "Not applicable for CV",
    credit: "Credit",
    initialValue: "Initial value",
    creditNo: "Credit No.",
    vatIncluded: "VAT included",
    vatNotIncludedInCredit:
        "This VAT doesn’t include anyone from the Credit usage",
};

export default translations;
