import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import { useRef } from "react";
import { generatePath, useNavigate } from "react-router";
import { useListMaterialLazyQuery } from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import TableAction from "../../../common/TableActions";
import { MATERIAL_STATUSES } from "../../../constant/material";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const MaterialTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();
    const [listMaterial] = useListMaterialLazyQuery();

    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "name",
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.MATERIAL_DETAIL, {
                        materialId: item.id,
                    })}
                >
                    {name}
                </AppLink>
            ),
        },
        {
            title: Messages.sku,
            dataIndex: "sku",
        },
        {
            title: Messages.category,
            dataIndex: "category",
            render: (category) => category?.name,
        },
        {
            title: Messages.purchasingUnit,
            dataIndex: "purchaseUnit",
            render: (unit) => unit?.name ?? "",
        },
        {
            title: Messages.trackingUnit,
            dataIndex: "trackingUnit",
            render: (unit) => unit?.name ?? "",
        },
        {
            title: Messages.visibility,
            dataIndex: "status",
            width: 100,
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={MATERIAL_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.lastUpdated,
            dataIndex: "updatedAt",
            width: 100,
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (product: any) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, product)
                        }
                        dataSource={[
                            { id: "update", label: "update" },
                            { id: "detail", label: "detail" },
                        ]}
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        return listMaterial({
            variables: {
                paginate: {
                    page: pagingData?.pageIndex as any,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                    sort: {
                        createdAt: -1,
                    },
                },
            },
        });

        // const promise = new Promise((resolve) => resolve({ data: materials }));
        // return promise;
    };

    const onClickTableAction = (actionId: string, material: any) => {
        switch (actionId) {
            case "update":
                navigate(
                    generatePath(Path.MATERIAL_UPDATE, {
                        materialId: material.id,
                    })
                );
                break;
            default:
                navigate(
                    generatePath(Path.MATERIAL_DETAIL, {
                        materialId: material.id,
                    })
                );
        }
    };

    const onClickAddNew = () => {
        navigate(Path.MATERIAL_CREATE);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div>
            <HeaderTable
                onClickNew={onClickAddNew}
                label={Messages.material}
                onClickFilter={() => {}}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="app-layout__page-header"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
                transformer={(res) => {
                    return res?.data?.data?.data ?? [];
                }}
                getTotalItems={(res) => {
                    return res?.data?.data?.pagination?.items ?? 0;
                }}
            />
        </div>
    );
};

export default MaterialTable;
