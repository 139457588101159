import { DateInput } from "d-react-components";
import moment from "moment";
import React from "react";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import UploadAvatar from "../../../common/upload/UploadAvatar";
import { CUSTOMER_TITLES, GENDERS } from "../../../constant/customer";
import Messages from "../../../languages/Messages";

interface IPersonalInfoForm {
    form: any;
}

const PersonalInfoForm = ({ form: customerForm }: IPersonalInfoForm) => {
    const inputClassName = "col-6 mt-3";
    const formValues = customerForm?.values;
    const formErrors = customerForm?.errors;
    return (
        <div className="row p-3">
            <div className="col-12">
                <label>{Messages.profilePicture}</label>
                <UploadAvatar
                    value={formValues?.avatar}
                    onChange={(file: any) => {
                        customerForm.setFieldValue("avatar", file?.fileUrl);
                    }}
                />
            </div>
            <InputSelectForm
                allowClear={false}
                keyData="title"
                form={customerForm}
                dataSource={CUSTOMER_TITLES}
                getLabel={(item: any) => item.label}
                className={inputClassName}
                required
            />
            <InputTextForm
                keyData="firstNameTh"
                form={customerForm}
                className={inputClassName}
                required
            />
            <InputTextForm
                keyData="lastNameTh"
                form={customerForm}
                className={inputClassName}
                required
            />
            <InputTextForm
                keyData="firstNameEn"
                form={customerForm}
                className={inputClassName}
                required
            />
            <InputTextForm
                keyData="lastNameEn"
                form={customerForm}
                className={inputClassName}
                required
            />
            <InputTextForm
                keyData="nickname"
                form={customerForm}
                className={inputClassName}
                required
            />
            <DateInput
                allowClear={false}
                label={Messages.dateOfBirth}
                onChange={(value) =>
                    customerForm.setFieldValue("birthDay", value)
                }
                value={
                    formValues.birthDay
                        ? moment(formValues.birthDay)
                        : (null as any)
                }
                error={formErrors.birthDay}
                format="DD/MM/YYYY"
                className={inputClassName}
            />
            <InputSelectForm
                allowClear={false}
                keyData="gender"
                form={customerForm}
                dataSource={GENDERS}
                className={inputClassName}
                required
            />
        </div>
    );
};

export default PersonalInfoForm;
